<template>
  <v-container id="users-list" fluid tag="section">
    <v-layout row wrap>
      <v-col cols="12">
        <!-- <base-material-card color="primary" outlined> -->
        <v-app-bar flat color="white">
          <v-spacer></v-spacer>
          <usuario></usuario>
        </v-app-bar>
        <v-app-bar flat color="white">
          <v-text-field
            flat
            solo
            prepend-icon="mdi-magnify"
            placeholder="Digite algo"
            v-model="search"
            hide-details
            class=""
          ></v-text-field>
          <v-btn icon>
            <v-icon>mdi-filter-variant</v-icon>
          </v-btn>
        </v-app-bar>
        <v-divider></v-divider>
        <!-- <v-card-text class="pa-0"> -->

        <v-data-table
          flat
          outlined
          no-results-text="Nenhum resultado encontrado"
          :headers="complex.headers"
          :search="search"
          :items="items"
          :footer-props="{
            'items-per-page-options': [
              10,
              25,
              50,
              { text: 'Todos', value: -1 },
            ],
          }"
          class="elevation-1"
          item-key="name"
          show-select
          v-model="complex.selected"
        >
          <template slot="item" slot-scope="props">
            <tr :class="{ 'grey--text': props.item.disabled }">
              <td>
                <v-checkbox
                  primary
                  hide-details
                  v-model="props.selected"
                ></v-checkbox>
              </td>
              <td>
                <v-avatar size="36px">
                  <v-img
                    v-if="props.item.photoURL"
                    :src="props.item.photoURL"
                    :alt="props.item.displayName"
                  />
                </v-avatar>
              </td>
              <td>
                {{ props.item.displayName }}
                <v-chip v-if="props.item.disabled == true"> Desativada </v-chip>
              </td>
              <td>{{ props.item.email }}</td>
              <td>{{ props.item.cpfcnpj }}</td>
              <td>{{ props.item.franquia? props.item.franquia.titulo : '' }}</td>
              <td>
                <v-btn
                  depressed
                  title="Editar registro"
                  icon
                  fab
                  dark
                  color="green"
                  small
                  @click.native="editar(props.item)"
                >
                  <v-icon>mdi-account-edit</v-icon>
                </v-btn>
                <v-dialog
                  v-if="props.item.disabled == false"
                  v-model="props.item.dialog"
                  persistent
                  max-width="460"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      depressed
                      icon
                      fab
                      dark
                      color="red"
                      small
                      title="Desabilitar conta"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-account-cancel</v-icon>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="headline">
                      Desativar conta
                    </v-card-title>
                    <v-card-text
                      ><v-alert type="error">
                        Usuários com contas desativadas não podem fazer login.
                      </v-alert></v-card-text
                    >
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn text @click="props.item.dialog = false">
                        Cancelar
                      </v-btn>
                      <v-btn color="primary" @click="desativar(props.item)">
                        Desativar
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog
                  v-if="props.item.disabled == true"
                  v-model="props.item.dialog"
                  persistent
                  max-width="460"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      depressed
                      icon
                      fab
                      dark
                      color="primary"
                      small
                      title="Ativar conta"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-account-check</v-icon>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="headline">
                      Ativar conta
                    </v-card-title>
                    <v-card-text
                      ><v-alert type="info">
                        Usuários com contas ativadas conseguirão fazer login novamente.
                      </v-alert></v-card-text
                    >
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn text @click="props.item.dialog = false">
                        Cancelar
                      </v-btn>
                      <v-btn color="primary" @click="ativar(props.item)">
                        Ativar
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-btn
                  depressed
                  icon
                  fab
                  dark
                  color="info"
                  small
                  title="Reiniciar senha"
                  @click.native="resetPassword(props.item)"
                >
                  <v-icon>mdi-lock-reset</v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
        <!-- </v-card-text> -->
        <!-- </base-material-card> -->
      </v-col>
    </v-layout>
  </v-container>
</template>

<script>
import toastr from "toastr";
// import {admin} from "firebase-admin";
import { db } from "@/db";

// Utilities
import { mapState } from "vuex";
export default {
  components: {
    Usuario: () => import("@/views/dashboard/components/core/Usuario"),
  },
  firebase: {
    items: db.ref("/users/"),
  },
  data() {
    return {
      dialog: false,
      search: "",
      items: [],
      complex: {
        selected: [],
        headers: [
          {
            text: "Avatar",
            value: "photoURL",
          },
          {
            text: "Nome Completo",
            value: "displayName",
          },
          {
            text: "E-mail",
            value: "email",
          },
          {
            text: "CPF/CNPJ",
            value: "cpfcnpj",
          },
          {
            text: "Franquia",
            value: "franquia",
          },
          {
            text: "Ações",
            value: "",
          },
        ],
        items: [],
      },
    };
  },
  methods: {
    editar(user) {
      this.userEdit = user;
      this.dialogUser = true;
    },
    resetPassword(user){
      toastr.info(
        "Solicitando redefinição de senha para: " + user.displayName + ". Aguarde ...",
        "MCS Assessoria e Treinamentos"
      );
      this.$http.defaults.headers.common["Authorization"] =
        "Bearer " + this.$store.state.user.refreshToken;
      // console.log(newUser)
      this.$http
        .get("/sendPasswordResetLink/" + user.email)
        .then((response) => {
          toastr.success(
            "E-mail com redefinição de senha para o usuário: " + user.displayName + " enviado com sucesso!",
            "MCS Assessoria e Treinamentos"
          );
          user.dialog = false;
        })
        .catch((error) => {
          toastr.error(
            "Algo Inesperado aconteceu: " + error.error,
            "MCS Assessoria e Treinamentos"
          );
        });
    },
    desativar(user) {
      toastr.info(
        "Desativando o cadastro de: " + user.displayName + ". Aguarde ...",
        "MCS Assessoria e Treinamentos"
      );
      this.$http.defaults.headers.common["Authorization"] =
        "Bearer " + this.$store.state.user.refreshToken;
      // console.log(newUser)
      this.$http
        .get("/disableUser/" + user.uid)
        .then((response) => {
          toastr.success(
            "Usuário: " + user.displayName + " desativado com sucesso!",
            "MCS Assessoria e Treinamentos"
          );
          user.dialog = false;
        })
        .catch((error) => {
          toastr.error(
            "Algo Inesperado aconteceu: " + error.error,
            "MCS Assessoria e Treinamentos"
          );
        });
    },
    ativar(user) {
      toastr.info(
        "Ativando o cadastro de: " + user.displayName + ". Aguarde ...",
        "MCS Assessoria e Treinamentos"
      );
      this.$http.defaults.headers.common["Authorization"] =
        "Bearer " + this.$store.state.user.refreshToken;
      // console.log(newUser)
      this.$http
        .get("/enableUser/" + user.uid)
        .then((response) => {
          toastr.success(
            "Usuário: " + user.displayName + " ativado com sucesso!",
            "MCS Assessoria e Treinamentos"
          );
          user.dialog = false;
        })
        .catch((error) => {
          toastr.error(
            "Algo Inesperado aconteceu: " + error.error,
            "MCS Assessoria e Treinamentos"
          );
        });
    },
  },
  computed: {
    ...mapState(["user"]),
    userEdit: {
      get() {
        return this.$store.state.userEdit;
      },
      set(val) {
        this.$store.commit("SET_USER_EDIT", val);
      },
    },
    dialogUser: {
      get() {
        return this.$store.state.dialogUser;
      },
      set(val) {
        this.$store.commit("SET_DIALOG_USER", val);
      },
    },
  },
  created() {
    /*admin
      .auth()
      .getUsers()
      .then((getUsersResult) => {
        console.log("Successfully fetched user data:");
        getUsersResult.users.forEach((userRecord) => {
          console.log(userRecord);
        });

        console.log("Unable to find users corresponding to these identifiers:");
        getUsersResult.notFound.forEach((userIdentifier) => {
          console.log(userIdentifier);
        });
      })
      .catch((error) => {
        console.log("Error fetching user data:", error);
      });*/
  },
  updated() {
    // console.log(this.user);
  },
};
</script>